/* eslint no-console:0 */

// Update?

/**
 * core-js includes "polyfills for ECMAScript up to 2019" (at time of comment)
 * regenerator-runtime allows use of async/await and yield/generator functions
 * As such, they are not needed until we make use of ES6 or async features.
 * These can be included as we migrate.
 */
// import "core-js/stable";
// import "regenerator-runtime/runtime";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application
// logic in a relevant structure within app/javascript and only use these pack
// files to reference that code so it'll be compiled.

// import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"

// ActiveStorage.start()

import "jquery" // jQuery (1.12.4)

// jQuery UI (1.1.12)
import "webpack-jquery-ui/autocomplete"
import "webpack-jquery-ui/effects"

import "moment"

import "underscore"

import "el-transition"

import smoothscroll from "smoothscroll-polyfill"

/**
 * The following *.tree files replicate the behavior of sprockets require_tree
 * Each file manually includes scripts for it's respective directory.
 * This approach allows for continued sprockets require_tree support,
 * vs using an index.js file in each directory.
 */
import "@assets/javascripts/app.tree" // require_tree ./app
import "@assets/javascripts/ui.tree" // require_tree ./ui

import aria from "@assets/javascripts/aria"

import "quill/dist/quill.snow.css"

// Prevent double loading of hotwired on browser back.
// See: https://github.com/hotwired/turbo/issues/104
if (!window.Turbo) {
  require("@hotwired/turbo-rails")
}

/*
TODO: Refactor/remove.
  This is holdover from the original implementation of the site. We should
  replace/remove it – cs
*/
for (var i in App) {
  if (typeof App[i].initialize == "function") {
    App[i].initialize && App[i].initialize()
  }
}

$(document).on("click", "*[data-alert]", function (e) {
  alert($(e.currentTarget).data("alert"))
  return false
})

smoothscroll.polyfill()

global.aria = aria
